* {
  margin: 0px;
  padding: 0px;

  /* font-family: 'Roboto Slab', serif; */
}
.navbrand {
  padding: 0px !important;
}
.navbar a {
  margin: 0px 15px;
}
.navbar .active {
  color: red !important;
  border-bottom: 2px solid red;
}
@media screen and (max-width: 767px) {
  .navbar .active {
    color: red !important;
    border-bottom: none !important;
    width: 100px;
  }
}

@media screen and (max-width: 992px) {
  .navbar .active {
    border-bottom: none !important;
  }
}

.nav-link:hover {
  color: red !important;
}
.navbar {
  padding: 0px !important;
}
.nav-link {
  font-size: 17px !important;
  font-weight: 500 !important;
  color: black !important;
}
/* .nav-link a{
  margin: 0px 15px!important;
} */
.button {
  background-color: #e21f36 !important;
  position: relative;
  z-index: 1;
  transition: all 0.3s linear;
  overflow: hidden;
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #082862;
  width: 100%;
  height: 100%;
  transform: translate(-100%);
  transition: all 0.3s linear;
}
.button:hover::before {
  transform: translate(0);
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .button {
    background-color: #e21f36 !important;
    width: 140px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 992px) {
  .button {
    width: 150px !important;
  }
}
.button:hover {
  color: white !important;
}
.head {
  font-size: 50px !important;
}
.home-container {
  margin-top: 70px;
  background: #082862;
  max-height: 500px;
  display: block;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .home-container {
    background: #082862;
    max-height: 1000px;
    display: block;
    align-items: center;
    margin-top: 100px !important;
  }
}
.content-part {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
h5 {
  color: #e21f36 !important;
  font-size: 1.25rem !important;
}

.content-part h1 {
  color: #fff;
  font-size: 60px;
  font-weight: 700 !important;
  margin: 10px 0px;
}
.content-part p {
  color: #fff;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .content-part h5 {
    padding-top: 20px !important;
  }
}
@media screen and (max-width: 600px) {
  .content-part h1 {
    font-size: 26px;
  }
  p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 992px) {
  .content-part h1 {
    font-size: 35px !important;
  }
}

.img-section img {
  margin-top: 30px;
  padding: 20px 0px;
  width: 100%;
  height: 480px;
  transition: transform 0.2s;
}
.img-section img:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 600px) {
  .img-section img {
    margin-top: 20px;
    height: 350px;
  }
}

.btn-danger {
  border-radius: 0px !important;
  font-weight: 600 !important;
  font-size: 16px;
  color: #fff !important;
  padding: 10px 20px !important;
  position: relative;
  z-index: 1;
  transition: all 0.3s linear;
  overflow: hidden;
}
.logo-pictures {
  display: flex;
}
.logo-pictures img {
  padding: 10px;
  margin: 2px;
  max-height: 75px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0px 5px !important;
}

.btn-danger::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #082862;
  width: 100%;
  height: 100%;
  transform: translate(-100%);
  transition: all 0.3s linear;
}
.btn-danger:hover::before {
  transform: translate(0);
  z-index: -1;
}

.about-left img {
  width: 100%;
  height: 400px;
}
/* @media screen and (max-width:992px) {
  .about-left img{
    height: 590px;
  }
} */
@media screen and (max-width: 767px) {
  .about-left img {
    height: 320px;
  }
}
.four-card {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)),
    url(assets/challenges-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  max-height: 500px;
  z-index: 9;
  padding: 70px 0px;
  background-attachment: fixed;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .four-card {
    max-height: 1150px;
  }
}
.flip-card {
  background-color: transparent;
  height: 200px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #ffffff;
  color: black;
  padding: 50px 0px;
  border-radius: 5px;
}

.flip-card-back {
  padding: 50px 10px;
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
  border-radius: 5px;
}
.card-top {
  border: 0px !important;
  overflow: hidden;
}
.card-top img {
  height: 250px !important;
  width: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}
.card-top img:hover {
  transform: scale(1.1);
}
.card-title {
  font-size: 19px !important;
}

.counter-row {
  padding: 50px 0px;
}
.counter-box {
  border: 1px solid rgb(60, 58, 58);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
}
.counter-box:hover {
  transition: 0.5s ease-in-out;
  transform: translateY(-10px);
}
.counter-icon {
  background-color: rgb(240, 233, 233) !important;
  font-size: 40px;
  margin-bottom: 20px;
}
.counter {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.chalanges h3 {
  color: #fff;
}
.chalanges h2 {
  color: #fff;
}
.card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons {
  margin-top: 10px;
  font-size: 50px;
  color: #e21f36;
  line-height: 1;
  display: block;
}

.services {
  padding: 50px 0px;
  background-color: aliceblue;
}
.service-card {
  text-align: center;
  font-size: 19px !important;
}

.why-chooseus {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)),
    url(assets/counter-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
  height: 500px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .why-chooseus {
    height: 1150px !important;
  }
}

/* footer css */

.footer {
  background-color: #082862;
}

.import-link {
  position: relative;
}

.footer p a {
  text-decoration: none !important;
}

.text-reset {
  display: inline-block;
  transition: all 0.3s ease-out !important;
}

.text-reset:hover {
  transform: translate(7px) !important;
}
.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fb {
  background-color: #4267b2 !important;
  border-radius: 50%;
  padding: 2px 5px;
  text-align: center;
}
.twitter {
  background-color: #1da1f2;
  border-radius: 50%;
  padding: 2px 5px;
  text-align: center;
}
.instagram {
  background-color: #4c68d7;
  color: #fff;
  border-radius: 50%;
  padding: 2px 5px;
  text-align: center;
}
.linkdin {
  background-color: #0a66c2;
  color: #fff;
  border-radius: 50%;
  padding: 2px 5px;
  text-align: center;
}

.read-more {
  color: bisque;
  cursor: pointer;
}
.card-solution {
  border: 0px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19);
  /* border-radius: 0px!important; */
  position: relative;
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}
.card-solution::after,
.counter-box::after,
.card-solution::before,
.counter-box::before {
  position: absolute;
  content: "";
  border-style: solid;
  border-color: red;
  transition: 0.5s;
}
.card-solution::before,
.counter-box::before {
  height: 0;
  width: 100%;
  border-width: 0 2px;
  top: 50%;
  transform: translate(0px, -50%);
  border-radius: 5px;
}
.card-solution::after,
.counter-box::after {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
  border-width: 1px 0;
  border-radius: 5px;
}

.card-solution:hover {
  transform: scale(1.03);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.card-solution:hover::before,
.counter-box:hover::before {
  height: 100%;
}
.card-solution:hover::after,
.counter-box:hover::after {
  width: 100%;
}
.card-solution-body {
  padding: 30px !important;
}
.card-title-solution {
  color: #000 !important;
  font-size: 18px !important;
}
/* .solution-img{
  background-image: url(assets/solution-img.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 500px;
  width: 100%;
} */

.solution-img img {
  width: 100%;
  height: 480px;
}
@media screen and (max-width: 767px) {
  .solution-img {
    width: 100%;
    max-height: 300px !important;
    margin-top: 30px !important;
  }
}

/* @media screen and (max-width:767px) {
  .solution-img{
    margin-top: 40px!important;
    height: 400px;
  }
} */

/* 
about us page css */

.about-page {
  margin-top: 70px;
  background: #000219;
  padding: 100px 0px;
}
.about {
  margin: 60px 0px;
}
.about-text {
  color: red;
}
.about-home {
  color: #fff !important;
  text-decoration: none;
}
.about-home:hover {
  color: #e21f36 !important;
  transition: 0.5s;
}

/* contact us page css */

.contact-us {
  margin-top: 70px;
  background-color: #000219;
  padding: 100px 0px;
}
.form-section {
  margin: 50px 0px;
}
.form-control {
  border-radius: 0px !important;
}
.contact-address {
  display: flex;
  flex-direction: column;
}
.contact-address-icon {
  font-size: 30px;
  color: #e21f36;
  margin-bottom: 10px;
}

.form-control:focus {
  box-shadow: none !important;
}

/* 
service page css */

.services-section {
  margin-top: 70px;
  background-color: #000219;
  padding: 100px 0px;
}

.map-contact {
  background-color: #e21f36 !important;
}

.map-img {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .img-map {
    width: 100% !important;
  }
  .img-sol {
    height: 280px !important;
  }
}
